import MuiDialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Button } from "@mui/material";
import { useCallback, useState } from "react";
import { LoadingButton } from "@mui/lab";

type Props = {
  isOpen: boolean;
  title?: string;
  description?: string;
  cancelText?: string;
  submitText?: string;
  onSubmit?: () => void;
  onClose: () => void;
};

export const Dialog: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  title,
  description,
  children,
  submitText = "Kaydet",
  cancelText = "İptal",
  onSubmit: propOnSubmit,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = useCallback(async () => {
    try {
      setLoading(true);

      await propOnSubmit?.();

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, [propOnSubmit]);

  return (
    <MuiDialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{cancelText}</Button>
        <LoadingButton
          loading={loading}
          type="submit"
          color="error"
          onClick={onSubmit}
        >
          {submitText}
        </LoadingButton>
      </DialogActions>
    </MuiDialog>
  );
};
