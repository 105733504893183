import { useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";
import {
  Divider,
  List as MaterialList,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { ArrowRight } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { PageContainer } from "../../components/PageContainer";

export const Settings = () => {
  const navigate = useNavigate();

  const goPage = useCallback((route: string) => {
    navigate("/panel/settings/" + route);
  }, []);

  return (
    <PageContainer title="Ayarlar">
      <MaterialList sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItem
          disablePadding
          alignItems="center"
          onClick={() => goPage?.("deposit-items")}
        >
          <ListItemButton alignItems="center">
            <ListItemText primary="Emanet Türleri" />
            <ArrowRight fontSize="large" />
          </ListItemButton>
        </ListItem>
        <ListItem
          disablePadding
          alignItems="center"
          onClick={() => goPage?.("current-types")}
        >
          <ListItemButton alignItems="center">
            <ListItemText primary="Cari Türleri" />
            <ArrowRight fontSize="large" />
          </ListItemButton>
        </ListItem>
        <Divider />
      </MaterialList>
    </PageContainer>
  );
};
