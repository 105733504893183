import "./style.scss";
import ImageUploading, {
  ImageListType,
  ImageType,
} from "react-images-uploading";
import { useCallback, useEffect, useState } from "react";
import { UPLOADS_BASE_URL } from "../../consts";
import { Button } from "@mui/material";

type Props = {
  value?: string;
  onChange: (image: ImageType | null) => void;
};

export const ImageUpload: React.FC<Props> = ({
  value,
  onChange: propOnChange,
}) => {
  const [images, setImages] = useState<any>([]);

  const onChange = useCallback((imageList: ImageListType | null) => {
    if (imageList) {
      setImages(imageList);
      propOnChange(imageList[0]);
    } else {
      setImages([]);
      propOnChange(null);
    }
  }, []);

  useEffect(() => {
    if (value) {
      setImages([{ dataURL: UPLOADS_BASE_URL + value }]);
    }
  }, [value]);

  return (
    <ImageUploading value={images} onChange={onChange}>
      {({ imageList, onImageUpload, dragProps }) => (
        <div className="upload__image-wrapper">
          <div
            onClick={onImageUpload}
            {...dragProps}
            className="upload-container"
            style={{
              backgroundImage: imageList.length
                ? "url(" + imageList[0].dataURL + ")"
                : undefined,
            }}
          >
            {!imageList.length && <span>Fotoğraf</span>}
            {imageList.length > 0 && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onChange(null);
                }}
                variant="contained"
                color="error"
                size="small"
                className="remove-button"
              >
                Sil
              </Button>
            )}
          </div>
        </div>
      )}
    </ImageUploading>
  );
};
