import { useCallback } from "react";
import {
  Divider,
  Fab,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { List } from "components/List";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "@mui/icons-material";

import "./style.scss";

export const CurrentTypes = () => {
  const navigate = useNavigate();

  const goDetail = useCallback((item: any) => {
    navigate("/panel/settings/current-types/edit/" + item.id);
  }, []);

  const goAdd = useCallback(() => {
    navigate("/panel/settings/current-types/add");
  }, []);

  const renderRowTemplate = useCallback((item: any) => {
    return (
      <div key={item.id}>
        <ListItem
          disablePadding
          alignItems="center"
          onClick={() => goDetail?.(item)}
        >
          <ListItemButton alignItems="center">
            <ListItemText
              primary={item.name}
              secondary={"Tarih: " + item.created_date}
            />

            <ArrowRight fontSize="large" />
          </ListItemButton>
        </ListItem>
        <Divider />
      </div>
    );
  }, []);

  return (
    <div>
      <List
        title="Cari Türleri"
        table="current_types"
        fields={["id", "name", "created_date"]}
        searchFields={["name"]}
        rowTemplate={renderRowTemplate}
        rowCountPerPage={20}
      />
      <Fab
        onClick={goAdd}
        color="primary"
        sx={{ position: "fixed", right: 20, bottom: 20 }}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};
