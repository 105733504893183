import { useCallback, useContext, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PageContainer } from "components/PageContainer";
import { Box, Button } from "@mui/material";
import { Input } from "components/Input";
import { Dropdown } from "components/Dropdown";
import { usePost } from "services";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { t } from "locales";
import { LoadingButton } from "@mui/lab";
import AppContext from "store/AppContext";

import "./style.scss";

const schema = yup.object({
  type: yup.number(),
  name: yup.string().required(t("required")),
  phone: yup.string(),
  email: yup.string().email(t("invalid_email")),
  web: yup.string(),
});

export const CurrentUpsert = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: "",
      name: "",
      phone: "",
      email: "",
      web: "",
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = formMethods;

  const { data, loading, execute } = usePost("get");
  const {
    data: detailData,
    loading: loadingDetail,
    execute: executeDetail,
  } = usePost("get");

  const { loading: loadingCreate, execute: executeCreateForm } =
    usePost("create");

  const { loading: loadingUpdate, execute: executeUpdateForm } = usePost(
    "update",
    "PUT"
  );

  const { toggleBackdrop, showNotification } = useContext(AppContext);

  const getItems = useCallback(async () => {
    await execute({
      table: "current_types",
    });
  }, [execute]);

  useEffect(() => {
    if (!data && !loading) {
      getItems();
    }
  }, [data, loading]);

  const currentTypesOptions = useMemo(() => {
    return (
      data?.map((item: any) => ({ value: item.id, label: item.name })) || []
    );
  }, [data]);

  const onSubmit = useCallback(async (data: any) => {
    try {
      data.id = id ? parseInt(id) : undefined;

      await (id ? executeUpdateForm : executeCreateForm)({
        table: "currents",
        items: [data],
      });

      showNotification("Başarıyla tamamlandı!");

      navigate("/panel/currents");
    } catch (err) {}
  }, []);

  useEffect(() => {
    if (id) {
      executeDetail({
        table: "currents",
        filters: { id: parseInt(id) },
      });
    }
  }, [id]);

  useEffect(() => {
    if (detailData?.length) {
      const { type, name, web, phone, email } = detailData[0];

      setValue("type", type);
      setValue("name", name);
      setValue("phone", phone);
      setValue("web", web);
      setValue("email", email);
    } else if (currentTypesOptions?.length) {
      setValue("type", currentTypesOptions[0].value);
    }
  }, [detailData, currentTypesOptions]);

  useEffect(() => {
    toggleBackdrop(loadingDetail);
  }, [loadingDetail]);

  return (
    <PageContainer
      title={
        id
          ? detailData
            ? detailData?.[0]?.name + " - Cari Düzenle"
            : ""
          : "Yeni Cari Ekle"
      }
    >
      <Box sx={{ padding: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box paddingY={1}>
            <Dropdown
              name="type"
              label="Cari Türü"
              options={currentTypesOptions}
              control={control}
              errors={errors}
            />
          </Box>

          <Box paddingY={1}>
            <Input
              name="name"
              label="Ad"
              errors={errors}
              control={control}
              fullWidth
            />
          </Box>

          <Box paddingY={1}>
            <Input
              name="email"
              label="E-posta"
              type="email"
              errors={errors}
              control={control}
              fullWidth
            />
          </Box>
          <Box paddingY={1}>
            <Input
              name="web"
              label="Web sitesi"
              errors={errors}
              control={control}
              fullWidth
            />
          </Box>
          <Box paddingY={1}>
            <Input
              name="phone"
              label="Telefon"
              errors={errors}
              control={control}
              fullWidth
            />
          </Box>

          <Box
            paddingY={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button type="button" onClick={() => navigate(-1)}>
              İptal
            </Button>

            <LoadingButton
              type="submit"
              loading={loadingCreate || loadingUpdate}
              variant="contained"
            >
              Kaydet
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </PageContainer>
  );
};
