import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PageContainer } from "components/PageContainer";
import { Box, Button } from "@mui/material";
import { Input } from "components/Input";
import { Dropdown } from "components/Dropdown";
import { usePost } from "services";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { t } from "locales";
import { LoadingButton } from "@mui/lab";
import AppContext from "store/AppContext";

import "./style.scss";
import CurrentsInput from "../CurrentsInput";
import { ImageUpload } from "../../../components/ImageUpload";
import { ImageType } from "react-images-uploading";

const schema = yup.object({
  type: yup.number(),
  deposit_item: yup.number().required(t("required")),
  current: yup.number().required(t("required")),
  description: yup.string().required(t("required")),
});

export const DepositUpsert = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [newImage, setNewImage] = useState<File | undefined | null>();
  const [selectedCurrent, setSelectedCurrent] = useState<any>(null);

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      type: "0",
      deposit_item: "",
      current: undefined,
      description: "",
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = formMethods;

  const {
    data: depositItems,
    loading: loadingDepositItems,
    execute: executeDepositItems,
  } = usePost("get");
  const {
    data: detailData,
    loading: loadingDetail,
    execute: executeDetail,
  } = usePost("get");

  const { loading: loadingCreate, execute: executeCreateForm } =
    usePost("create");

  const { loading: loadingUpload, execute: executeUploadImage } =
    usePost("upload");

  const { loading: loadingDelete, execute: executeDeleteImage } = usePost(
    "delete-file",
    "DELETE"
  );

  const { loading: loadingUpdate, execute: executeUpdateForm } = usePost(
    "update",
    "PUT"
  );

  const { toggleBackdrop, showNotification } = useContext(AppContext);

  const getOptions = useCallback(async () => {
    executeDepositItems({
      table: "deposit_items",
    });
  }, []);

  useEffect(() => {
    getOptions();
  }, []);

  const depositItemsOptions = useMemo(() => {
    return (
      depositItems?.map((item: any) => ({
        value: item.id,
        label: item.name,
      })) || []
    );
  }, [depositItems]);

  const onChangeImage = useCallback(async (image: ImageType | null) => {
    if (image) {
      setNewImage(image.file);
    } else {
      setNewImage(null);
    }
  }, []);

  const onSubmit = useCallback(
    async (data: any) => {
      let image = null;

      try {
        if (newImage) {
          if (id && detailData[0].image) {
            await executeDeleteImage([detailData[0].image]);
          }

          const formData = new FormData();
          formData.append("files", newImage);

          const fileResponse = await executeUploadImage(formData);
          image = fileResponse.data.data[0].fileName;
        } else if (newImage === null && id) {
          if (detailData[0].image) {
            await executeDeleteImage([detailData[0].image]);
          }

          image = null;
        } else {
          image = id ? detailData[0].image : null;
        }

        data.id = id ? parseInt(id) : undefined;
        data.image = image;

        await (id ? executeUpdateForm : executeCreateForm)({
          table: "deposits",
          items: [data],
        });

        showNotification("Başarıyla tamamlandı!");

        navigate("/panel/deposits");
      } catch (err) {}
    },
    [newImage, detailData]
  );

  useEffect(() => {
    if (id) {
      executeDetail({
        table: "deposits",
        fields: [
          "deposits.id as id",
          "deposits.type as type",
          "description",
          "image",
          "deposit_item",
          "current",
          "currents.name as current_name",
        ],
        innerJoins: [["currents", "deposits.current", "currents.id"]],
        filters: { "deposits.id": parseInt(id) },
      });
    }
  }, [id]);

  useEffect(() => {
    if (detailData?.length) {
      const { type, deposit_item, description } = detailData[0];

      setValue("type", type);
      setValue("deposit_item", deposit_item);
      setValue("description", description);
    } else if (depositItemsOptions?.length) {
      setValue("deposit_item", depositItemsOptions[0].value);
    }
  }, [detailData, depositItemsOptions]);

  useEffect(() => {
    if (selectedCurrent) {
      setValue("current", selectedCurrent.id);
    } else {
      setValue("current", undefined);
    }
  }, [selectedCurrent]);

  useEffect(() => {
    toggleBackdrop(loadingDetail);
  }, [loadingDetail]);

  return (
    <PageContainer
      title={
        id
          ? detailData
            ? detailData?.[0]?.current_name + " - Emanet Düzenle"
            : ""
          : "Yeni Emanet Ekle"
      }
    >
      <Box sx={{ padding: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box paddingY={1}>
            <Dropdown
              name="type"
              label="Emanet Tipi"
              options={[
                { value: "0", label: "Alındı" },
                { value: "1", label: "Verildi" },
                { value: "2", label: "Tamamlandı" },
              ]}
              control={control}
              errors={errors}
            />
          </Box>

          <Box paddingY={1}>
            <Dropdown
              name="deposit_item"
              label="Emanet Türü"
              options={depositItemsOptions}
              control={control}
              errors={errors}
            />
          </Box>

          <Box paddingY={1}>
            <CurrentsInput
              errors={errors}
              name="current"
              value={detailData?.[0].current_name || undefined}
              onChange={(value) => setSelectedCurrent(value)}
            />
          </Box>

          <Box paddingY={1}>
            <Input
              name="description"
              label="Açıklama"
              multiline
              rows={4}
              errors={errors}
              control={control}
              fullWidth
            />
          </Box>

          <Box paddingY={1}>
            <ImageUpload
              value={detailData?.[0]?.image}
              onChange={onChangeImage}
            />
          </Box>

          <Box
            paddingY={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button type="button" onClick={() => navigate(-1)}>
              İptal
            </Button>

            <LoadingButton
              type="submit"
              loading={
                loadingCreate || loadingUpdate || loadingDelete || loadingUpload
              }
              variant="contained"
            >
              Kaydet
            </LoadingButton>
          </Box>
        </form>
      </Box>
    </PageContainer>
  );
};
