import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "components/PageContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  TextField,
} from "@mui/material";
import { usePost } from "services";
import AppContext from "store/AppContext";
import Typography from "@mui/material/Typography";
import { ConfirmModal } from "components/ConfirmModal";
import { DepositType } from "../";
import { BuildTwoTone, Share } from "@mui/icons-material";
import { DATE_TIME_FORMAT, UPLOADS_BASE_URL } from "../../../consts";

import "./style.scss";
import { MatrixInput } from "../../../components/MatrixInput";
import moment from "moment";

export const DepositDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const {
    data: detailData,
    loading: loadingDetail,
    execute: executeDetail,
  } = usePost("get");

  const { execute: executeDeleteRecord } = usePost("delete", "DELETE");

  const { execute: executeDeleteImage } = usePost("delete-file", "DELETE");

  const { loading: loadingUpdate, execute: executeUpdateForm } = usePost(
    "update",
    "PUT"
  );

  const { toggleBackdrop, showNotification } = useContext(AppContext);

  const item = useMemo(
    () => (detailData?.length ? detailData[0] : null),
    [detailData]
  );

  const onConfirmDeleteModal = useCallback(async () => {
    try {
      await executeDeleteRecord({
        table: "deposits",
        items: [item?.id],
      });

      if (item?.image) {
        await executeDeleteImage([item?.image]);
      }

      showNotification("Başarıyla tamamlandı!");

      navigate("/panel/deposits");
    } catch (err) {}
  }, [item]);

  const onCloseDeleteModal = useCallback(() => {
    setDeleteModalIsOpen(false);
  }, []);

  useEffect(() => {
    if (id) {
      executeDetail({
        table: "deposits",
        filters: { "deposits.id": parseInt(id) },
        fields: [
          "deposits.id as id",
          "deposits.type as type",
          "description",
          "additional_data",
          "image",
          "currents.name as current_name",
          "deposit_items.name as deposit_item_name",
          "deposits.created_date as created_date",
          "deposits.updated_date as updated_date",
        ],
        innerJoins: [
          ["currents", "deposits.current", "currents.id"],
          ["deposit_items", "deposits.deposit_item", "deposit_items.id"],
        ],
      });
    }
  }, [id]);

  const onAddData = useCallback(
    async (value: string) => {
      if (!value) return;

      const newItem = {
        ...item,
        additional_data: item.additional_data
          ? JSON.parse(item.additional_data)
          : [],
      };

      newItem.additional_data.push({
        created_at: moment().format(DATE_TIME_FORMAT),
        description: value,
      });

      const newData = {
        table: "deposits",
        items: [
          { id, additional_data: JSON.stringify(newItem.additional_data) },
        ],
      };

      await executeUpdateForm(newData);

      window.location.reload();
    },
    [id, item]
  );

  const onRemoveData = useCallback(
    async (index: number) => {
      const newItem = {
        ...item,
        additional_data: item.additional_data
          ? JSON.parse(item.additional_data)
          : [],
      };

      newItem.additional_data.splice(index, 1);

      const newData = {
        table: "deposits",
        items: [
          { id, additional_data: JSON.stringify(newItem.additional_data) },
        ],
      };

      await executeUpdateForm(newData);

      window.location.reload();
    },
    [id, item]
  );

  useEffect(() => {
    toggleBackdrop(loadingDetail);
  }, [loadingDetail]);

  return (
    <PageContainer title={item ? item.current_name + " - Emanet Detay" : ""}>
      <Box sx={{ padding: 2 }}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography variant="h6" component="div">
              {item?.current_name || "-"}
            </Typography>
            <Divider></Divider>
            <Typography variant="body2" paddingY={1}>
              <b>Tür:</b> ({" "}
              <Typography
                component="span"
                color={
                  item?.type === DepositType.Taking
                    ? "green"
                    : item?.type === DepositType.Giving
                    ? "red"
                    : "blue"
                }
              >
                {item?.deposit_item_name.trim()}&nbsp;
                {item?.type === DepositType.Taking
                  ? "Alındı"
                  : item?.type === DepositType.Done
                  ? "Tamamlandı"
                  : "Verildi"}
              </Typography>
              &nbsp;)
            </Typography>
            <Typography variant="body2" paddingY={1} fontWeight="bold">
              Açıklama:
            </Typography>
            <Card variant="outlined">
              <CardContent>{item?.description}</CardContent>
            </Card>

            <Typography variant="body2" paddingY={1} fontWeight="bold">
              Ek Bilgiler:
            </Typography>
            <Card variant="outlined">
              <CardContent>
                <div
                  style={{
                    marginBottom:
                      item?.additional_data &&
                      JSON.parse(item.additional_data).length > 0
                        ? "20px"
                        : 0,
                  }}
                >
                  {item?.additional_data
                    ? JSON.parse(item.additional_data).map(
                        (field: any, i: number) => (
                          <div
                            key={i}
                            style={{
                              borderBottom: "1px solid lightgray",
                              paddingTop: "5px",
                              paddingBottom: "5px",
                            }}
                          >
                            <b>{i + 1}.</b> {field.description} - <b>Tarih:</b>{" "}
                            {field.created_at} -{" "}
                            <span
                              style={{
                                color: "red",
                                cursor: "pointer",
                                border: "1px solid red",
                                padding: "0px 5px",
                              }}
                              onClick={() => onRemoveData(i)}
                            >
                              X
                            </span>
                          </div>
                        )
                      )
                    : ""}
                </div>
                <div>
                  <MatrixInput onAdd={onAddData} />
                </div>
              </CardContent>
            </Card>

            {item?.image && (
              <>
                <Typography variant="body2" paddingY={1} fontWeight="bold">
                  Fotoğraf:
                </Typography>
                <Card variant="outlined">
                  <CardContent>
                    <a href={UPLOADS_BASE_URL + item?.image} target="_blank">
                      <img src={UPLOADS_BASE_URL + item?.image} height={100} />
                    </a>
                  </CardContent>
                </Card>
              </>
            )}

            <Divider></Divider>
            <Typography variant="body2" paddingY={1}>
              <b>Oluşturma Tarihi:</b> {item?.created_date || "-"}
            </Typography>
            <Typography variant="body2" paddingY={1}>
              <b>Güncelleme Tarihi:</b> {item?.updated_date || "-"}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Box
        paddingX={2}
        paddingY={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button type="button" onClick={() => navigate(-1)}>
          Geri
        </Button>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Box paddingRight={1}>
            <Button
              type="button"
              variant="contained"
              color="info"
              size="small"
              onClick={() =>
                window.open(
                  `whatsapp://send?text=Emanet > ${
                    item.current_name
                  } > ${item?.deposit_item_name.trim()} ${
                    item?.type === DepositType.Taking
                      ? "Alındı"
                      : item?.type === DepositType.Done
                      ? "Tamamlandı"
                      : "Verildi"
                  } > ${item?.description} ${
                    item?.image
                      ? " > Foto >" + UPLOADS_BASE_URL + item?.image
                      : ""
                  }`
                )
              }
            >
              <Share />
            </Button>
          </Box>
          <Box paddingRight={1}>
            <Button
              type="button"
              variant="contained"
              color="warning"
              size="small"
              onClick={() => navigate("/panel/deposits/edit/" + id)}
            >
              Düzenle
            </Button>
          </Box>
          <Button
            type="button"
            variant="contained"
            color="error"
            size="small"
            onClick={() => setDeleteModalIsOpen(true)}
          >
            SİL
          </Button>
        </div>
      </Box>
      <ConfirmModal
        isOpen={deleteModalIsOpen}
        onConfirm={onConfirmDeleteModal}
        onClose={onCloseDeleteModal}
        title="Silme işlemi"
      >
        "{item?.current_name}" isimli emanet kaydı silinecektir. Emin misiniz?
      </ConfirmModal>
    </PageContainer>
  );
};
