import { Dialog } from "../Dialog";

type Props = {
  onConfirm?: () => void;
  onClose: () => void;
  title?: string;
  isOpen: boolean;
};

export const ConfirmModal: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  isOpen,
  title = "Onay",
  onConfirm,
  onClose,
}) => {
  return (
    <Dialog
      isOpen={isOpen}
      title={title}
      submitText="Onay"
      onSubmit={onConfirm}
      onClose={onClose}
    >
      {children || "Emin misiniz?"}
    </Dialog>
  );
};
