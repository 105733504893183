import "./style.scss";
import { Button, TextField } from "@mui/material";
import { useCallback, useState } from "react";

export const MatrixInput: React.FC<any> = ({ onAdd }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string | null>("");
  const handleOnChange = useCallback((e: any) => {
    setValue(e.target.value);
  }, []);

  const handleOnClickAdd = useCallback(async () => {
    setLoading(true);
    await onAdd?.(value);
    setLoading(false);
  }, [value]);

  return (
    <div className="matrix-input-container">
      <TextField
        variant="outlined"
        type="text"
        size="small"
        label="Yeni Kayıt"
        value={value}
        onChange={handleOnChange}
      />
      <Button onClick={handleOnClickAdd} disabled={loading || !value}>
        {loading ? "EKLENİYOR" : "EKLE"}
      </Button>
    </div>
  );
};
