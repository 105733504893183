import "./style.scss";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  SelectProps,
  MenuItem,
} from "@mui/material";
import { Controller } from "react-hook-form";

type OptionType = { value: string | number; label: string };

type Props = {
  register?: any;
  control?: any;
  name: string;
  value?: any;
  onChange?: (value: any) => void;
  options: OptionType[];
  errors?: any;
} & SelectProps;

export const Dropdown: React.FC<React.PropsWithChildren<Props>> = ({
  register = () => {},
  control,
  name,
  value,
  onChange,
  options,
  errors,
  ...rest
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel id={"dd-" + name}>{rest.label}</InputLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Select
              labelId={"dd-" + name}
              id={"dd-" + name}
              label={rest.label}
              {...rest}
              {...field}
              error={name && errors?.[name]}
            >
              {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />

      {name && errors?.[name] && (
        <FormHelperText>{errors[name].message}</FormHelperText>
      )}
    </FormControl>
  );
};
