import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageContainer } from "components/PageContainer";
import { Box, Button, Card, CardContent, Divider } from "@mui/material";
import { usePost } from "services";
import AppContext from "store/AppContext";
import Typography from "@mui/material/Typography";
import { ConfirmModal } from "components/ConfirmModal";

import "./style.scss";
import { DepositType } from "../../Deposits";
import { Share } from "@mui/icons-material";

export const CurrentDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const {
    data: detailData,
    loading: loadingDetail,
    execute: executeDetail,
  } = usePost("get");

  const { execute: executeDeleteRecord } = usePost("delete", "DELETE");

  const { data: currentTypes, execute: executeCurrentTypes } = usePost("get");

  const { toggleBackdrop, showNotification } = useContext(AppContext);

  const item = useMemo(
    () => (detailData?.length ? detailData[0] : null),
    [detailData]
  );

  const onConfirmDeleteModal = useCallback(async () => {
    try {
      await executeDeleteRecord({
        table: "currents",
        items: [item?.id],
      });

      showNotification("Başarıyla tamamlandı!");

      navigate("/panel/currents");
    } catch (err) {}
  }, [item]);

  const onCloseDeleteModal = useCallback(() => {
    setDeleteModalIsOpen(false);
  }, []);

  const currentType = useMemo(
    () =>
      item
        ? currentTypes?.find((type: any) => type.id === item.type)?.name
        : "",
    [item, currentTypes]
  );

  useEffect(() => {
    if (id) {
      executeDetail({
        table: "currents",
        filters: { id: parseInt(id) },
      });
    }
  }, [id]);

  useEffect(() => {
    executeCurrentTypes({
      table: "current_types",
    });
  }, [id]);

  useEffect(() => {
    toggleBackdrop(loadingDetail);
  }, [loadingDetail]);

  return (
    <PageContainer title={item ? item.name + " - Cari Detay" : ""}>
      <Box sx={{ padding: 2 }}>
        <Card sx={{ minWidth: 275 }}>
          <CardContent>
            <Typography variant="h6" component="div">
              {item?.name || "-"}
            </Typography>
            <Divider></Divider>
            <Typography variant="body2" paddingY={1}>
              <b>Cari Türü:</b> {currentType || "-"}
            </Typography>
            <Typography variant="body2" paddingY={1}>
              <b>E-posta:</b> {item?.email || "-"}
            </Typography>
            <Typography variant="body2" paddingY={1}>
              <b>Telefon:</b> {item?.phone || "-"}
            </Typography>
            <Typography variant="body2" paddingY={1}>
              <b>Web sitesi:</b> {item?.web || "-"}
            </Typography>
            <Divider></Divider>
            <Typography variant="body2" paddingY={1}>
              <b>Oluşturma Tarihi:</b> {item?.created_date || "-"}
            </Typography>
            <Typography variant="body2" paddingY={1}>
              <b>Güncelleme Tarihi:</b> {item?.updated_date || "-"}
            </Typography>
          </CardContent>
        </Card>
      </Box>

      <Box
        paddingX={2}
        paddingY={2}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Button type="button" onClick={() => navigate(-1)}>
          Geri
        </Button>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Box paddingRight={1}>
            <Button
              type="button"
              variant="contained"
              color="info"
              size="small"
              onClick={() =>
                window.open(
                  `whatsapp://send?text=Cari > ${item.name} > ${currentType} > ${item?.email} ${item?.phone} ${item?.web}`
                )
              }
            >
              <Share />
            </Button>
          </Box>
          <Box paddingRight={1}>
            <Button
              type="button"
              variant="contained"
              color="warning"
              size="small"
              onClick={() => navigate("/panel/currents/edit/" + id)}
            >
              Düzenle
            </Button>
          </Box>
          <Button
            type="button"
            variant="contained"
            color="error"
            size="small"
            onClick={() => setDeleteModalIsOpen(true)}
          >
            SİL
          </Button>
        </div>
      </Box>
      <ConfirmModal
        isOpen={deleteModalIsOpen}
        onConfirm={onConfirmDeleteModal}
        onClose={onCloseDeleteModal}
        title="Silme işlemi"
      >
        "{item?.name}" isimli cari kaydı silinecektir. Emin misiniz?
      </ConfirmModal>
    </PageContainer>
  );
};
