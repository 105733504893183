import React, { useState, createContext, useEffect } from "react";
import {
  Alert,
  Snackbar,
  AlertColor,
  Backdrop,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { green } from "@mui/material/colors";

const getTheme = (theme: StateType["theme"]) =>
  createTheme({
    palette: {
      mode: theme,
      primary: green,
    },
  });

type StateType = {
  theme: "dark" | "light";
  toast: {
    isOpen: boolean;
    type: AlertColor;
    message?: string;
  };
  backdrop: {
    isOpen: boolean;
  };
};

const data: StateType = {
  theme: "light",
  toast: {
    isOpen: false,
    type: "success",
    message: "",
  },
  backdrop: {
    isOpen: false,
  },
};

const AppContext = createContext({
  ...data,
  showNotification(message: string, type: AlertColor = "success") {},
  toggleBackdrop(status: boolean) {},
  setTheme(theme: StateType["theme"]) {},
});

export const AppProvider = ({ children }: React.PropsWithChildren) => {
  const [state, setState] = useState(data);

  const showNotification = (message: string, type: AlertColor = "success") => {
    setState((state) => ({
      ...state,
      toast: {
        isOpen: true,
        type,
        message,
      },
    }));
  };

  const toggleBackdrop = (status: boolean) => {
    setState((state) => ({
      ...state,
      backdrop: {
        isOpen: status,
      },
    }));
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setState((state) => ({
      ...state,
      toast: {
        ...state.toast,
        isOpen: false,
      },
    }));
  };

  const setTheme = (theme: StateType["theme"]) => {
    setState((state) => ({
      ...state,
      theme,
    }));
  };

  return (
    <AppContext.Provider
      value={{ ...state, showNotification, toggleBackdrop, setTheme }}
    >
      <ThemeProvider theme={getTheme(state.theme)}>
        <CssBaseline />

        {children}
        <Snackbar
          open={state.toast.isOpen}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.toast.type}
            sx={{ width: "100%" }}
          >
            {state.toast.message}
          </Alert>
        </Snackbar>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={state.backdrop.isOpen}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default AppContext;
