import useAxios from "axios-hooks";
import { useLocalStorage } from "../utils/useLocalStorage";
import { useCallback, useState } from "react";

const CACHE_DATA: any = {};

export function useGet(url: string) {
  const [{ data, loading, error }, refetch, cancelRequest] = useAxios(url);

  return {
    data,
    loading,
    error,
    refetch,
    cancelRequest,
  };
}

export function usePost(url: string, method = "POST") {
  const [token, setToken, removeToken] = useLocalStorage<string>("token", "");
  const [cacheData, setCacheData] = useState<any>(null);

  const [{ data, loading, error }, executeRequest] = useAxios(
    {
      url,
      method,
    },
    { manual: true }
  );

  const execute = useCallback(
    async (body: any) => {
      const stringBody = JSON.stringify(body);

      if (url === "get" && CACHE_DATA[body.table]) {
        const cachedData = CACHE_DATA[body.table];

        if (cachedData[stringBody]) {
          setCacheData(cachedData[stringBody]);
          return;
        }
      } else if (CACHE_DATA[body.table]) {
        if (CACHE_DATA[body.table][stringBody]) {
          setCacheData(null);
          delete CACHE_DATA[body.table][stringBody];
        }
      }

      let response;

      try {
        response = await executeRequest({
          data: body,
          headers: {
            Authorization: token ? "Bearer " + token : undefined,
          },
        });

        if (url === "get") {
          CACHE_DATA[body.table] = CACHE_DATA[body.table] || {};
          CACHE_DATA[body.table][stringBody] = response.data;
          setCacheData(null);
        } else {
          delete CACHE_DATA[body.table];
        }
      } catch (err: any) {
        if (err?.response?.status === 401) {
          removeToken();
          window.location.href = "/";
        }

        response = err;
      }

      return response;
    },
    [token, loading, executeRequest, setToken]
  );

  return {
    data: cacheData || data,
    loading,
    error,
    execute,
  };
}
