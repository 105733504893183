import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

import "./style.scss";

type Props = React.PropsWithChildren & {
  title?: string;
  rightContainer?: JSX.Element;
};

export const PageContainer: React.FC<Props> = ({
  title,
  rightContainer,
  children,
}) => {
  return (
    <div>
      <Box
        alignItems="center"
        sx={{
          position: "fixed",
          display: "flex",
          justifyContent: "space-between",
          top: 56,
          zIndex: 10,
          width: "100%",
          height: 55,
          backgroundColor: "background.paper",
        }}
      >
        <Typography variant="h6" fontWeight="bold" paddingLeft={2}>
          {title}
        </Typography>
        {rightContainer}
      </Box>
      <div>{children}</div>
    </div>
  );
};
