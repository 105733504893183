import * as React from "react";
import { debounce, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { usePost } from "../../../services";
import { useCallback, useEffect, useState } from "react";

type Props = {
  errors?: any;
  name?: string;
  value?: string;
  onChange?: (value: any) => void;
};

export default function CurrentsInput({
  errors,
  name,
  value: propValue,
  onChange: propOnChange,
}: Props) {
  const [value, setValue] = useState<any | null>(null);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const { data: currents, loading, execute: executeCurrents } = usePost("get");

  const fetchCurrents = useCallback(
    debounce((input: string) => {
      input?.length > 2 &&
        executeCurrents({
          table: "currents",
          filters: { name: input },
        });
    }, 400),
    []
  );

  useEffect(() => {
    if (propValue) {
      fetchCurrents(propValue);
    }
  }, [propValue]);

  useEffect(() => {
    if (propValue && !value && currents && isFirstLoad) {
      setValue(currents[0]);
      propOnChange?.(currents[0]);
      setIsFirstLoad(false);
    }
  }, [propValue, value, currents, isFirstLoad, propOnChange]);

  return (
    <div>
      <Autocomplete
        fullWidth
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        filterOptions={(x) => x}
        options={currents || []}
        autoComplete
        loading={loading}
        includeInputInList
        filterSelectedOptions
        value={value}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        noOptionsText="Cari bulunamadı."
        onChange={(event: any, newValue: any | null) => {
          setValue(newValue);
          propOnChange?.(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          fetchCurrents(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Cari Ara"
            fullWidth
            error={!!(name && errors?.[name])}
          />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props} key={option.id}>
              <Grid container alignItems="center">
                {option.name}
              </Grid>
            </li>
          );
        }}
      />
      {name && errors?.[name] && (
        <FormHelperText>{errors[name].message}</FormHelperText>
      )}
    </div>
  );
}
