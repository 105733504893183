import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { PageContainer } from "components/PageContainer";
import { Box, Button } from "@mui/material";
import { Input } from "components/Input";
import { Dropdown } from "components/Dropdown";
import { usePost } from "services";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { t } from "locales";
import { LoadingButton } from "@mui/lab";
import AppContext from "store/AppContext";
import { ConfirmModal } from "../../../../components/ConfirmModal";

import "./style.scss";

const schema = yup.object({
  name: yup.string().required(t("required")),
});

export const DepositItemUpsert = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = formMethods;

  const {
    data: detailData,
    loading: loadingDetail,
    execute: executeDetail,
  } = usePost("get");

  const { loading: loadingCreate, execute: executeCreateForm } =
    usePost("create");

  const { loading: loadingUpdate, execute: executeUpdateForm } = usePost(
    "update",
    "PUT"
  );

  const { execute: executeDeleteRecord } = usePost("delete", "DELETE");

  const onConfirmDeleteModal = useCallback(async () => {
    try {
      await executeDeleteRecord({
        table: "deposit_items",
        items: [parseInt(id as string)],
      });

      showNotification("Başarıyla tamamlandı!");
      navigate("/panel/settings/deposit-items");
    } catch (err) {}
  }, []);

  const onCloseDeleteModal = useCallback(() => {
    setDeleteModalIsOpen(false);
  }, []);

  const { toggleBackdrop, showNotification } = useContext(AppContext);

  const onSubmit = useCallback(async (data: any) => {
    try {
      data.id = id ? parseInt(id) : undefined;

      await (id ? executeUpdateForm : executeCreateForm)({
        table: "deposit_items",
        items: [data],
      });

      showNotification("Başarıyla tamamlandı!");

      navigate("/panel/settings/deposit-items");
    } catch (err) {}
  }, []);

  useEffect(() => {
    if (id) {
      executeDetail({
        table: "deposit_items",
        filters: { id: parseInt(id) },
      });
    }
  }, [id]);

  useEffect(() => {
    if (detailData?.length) {
      const { name } = detailData[0];

      setValue("name", name);
    }
  }, [detailData]);

  useEffect(() => {
    toggleBackdrop(loadingDetail);
  }, [loadingDetail]);

  return (
    <PageContainer
      title={
        id
          ? detailData
            ? detailData?.[0]?.name + " - Emanet Türü Düzenle"
            : ""
          : "Yeni Emanet Türü"
      }
    >
      <Box sx={{ padding: 2 }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box paddingY={1}>
            <Input
              name="name"
              label="Ad"
              errors={errors}
              control={control}
              fullWidth
            />
          </Box>

          <Box
            paddingY={2}
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button type="button" onClick={() => navigate(-1)}>
              İptal
            </Button>

            <div style={{ display: "flex", alignItems: "center" }}>
              {detailData && (
                <Box paddingRight={1}>
                  <Button
                    type="button"
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => setDeleteModalIsOpen(true)}
                  >
                    SİL
                  </Button>
                </Box>
              )}

              <LoadingButton
                type="submit"
                loading={loadingCreate || loadingUpdate}
                variant="contained"
              >
                Kaydet
              </LoadingButton>
            </div>
          </Box>
        </form>
      </Box>
      <ConfirmModal
        isOpen={deleteModalIsOpen}
        onConfirm={onConfirmDeleteModal}
        onClose={onCloseDeleteModal}
        title="Silme işlemi"
      >
        "{detailData?.[0].name}" isimli kayıt silinecektir. Emin misiniz?
      </ConfirmModal>
    </PageContainer>
  );
};
