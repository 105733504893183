const locales = {
  required: 'Bu alan zorunludur.',
  invalid_email: 'Lütfen geçerli bir e-posta adresi giriniz.',
  required_number: 'Lütfen bir rakam giriniz.',

  back: 'Geri',
  add: 'Ekle',
  detail: 'Detay',
  filter: 'Filtrele',

  cancel: 'İptal',
  remove: 'Sil',
  save: 'Kaydet',
};

export const t = key => {
  return locales[key];
};
