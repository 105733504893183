import { usePost } from "../../services";
import { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "store/AppContext";
import { debounce, List as MaterialList, Pagination } from "@mui/material";
import { Input } from "../Input";
import Box from "@mui/material/Box";
import { PageContainer } from "../PageContainer";
import { useForm } from "react-hook-form";

import "./style.scss";

type Props = {
  table: string;
  title?: string;
  rowTemplate: (item: any, index?: number) => React.ReactNode;
  rowCountPerPage?: number;
  fields?: Array<string>;
  searchFields?: Array<string>;
  innerJoins?: Array<Array<string>>;
  placeholderSearch?: string;
  onClickItem?: (item: any) => void;
};

export const List: React.FC<Props> = ({
  table,
  title,
  rowTemplate,
  rowCountPerPage = 20,
  fields,
  searchFields,
  innerJoins,

  placeholderSearch = "Arama",
}) => {
  const formMethods = useForm({
    defaultValues: {
      search: "",
    },
  });

  const { control } = formMethods;

  const { data, loading, execute } = usePost("get");
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [lastPage, setLastPage] = useState(0);

  const getItems = useCallback(
    async (searchValue?: string, pageNumber?: number) => {
      const filters = searchValue
        ? searchFields?.reduce((c, n) => {
            c[n] = searchValue;

            return c;
          }, {} as any)
        : undefined;

      await execute({
        table,
        filters,
        fields,
        innerJoins,
        pagination: { perPage: rowCountPerPage, currentPage: pageNumber || 1 },
      });
    },
    [execute]
  );

  const { toggleBackdrop } = useContext(AppContext);

  const renderRows = useCallback(() => {
    return data?.data.map((item: any, i: number) => rowTemplate(item, i));
  }, [data]);

  const onInputSearch = useCallback(
    debounce((value: string) => {
      setSearchValue(value);
      setPage(1);
      getItems(value, 1);
      window.scrollTo(0, 0);
    }, 400),
    [getItems]
  );

  const onChangePage = useCallback(
    (e: React.ChangeEvent<unknown>, pageNumber: number) => {
      setPage(pageNumber);
      getItems(searchValue, pageNumber);
      window.scrollTo(0, 0);
    },
    [searchValue, getItems]
  );

  useEffect(() => {
    if (!data && !loading) {
      getItems();
    } else if (data?.pagination?.lastPage) {
      setLastPage(data?.pagination?.lastPage);
    }
  }, [data, loading]);

  useEffect(() => {
    toggleBackdrop(loading);
  }, [loading]);

  return (
    <div>
      <PageContainer
        title={title}
        rightContainer={
          <Box paddingRight={2}>
            <Input
              name="search"
              control={control}
              placeholder={placeholderSearch}
              onInput={(e) => onInputSearch((e.target as any).value)}
            />
          </Box>
        }
      ></PageContainer>
      <MaterialList sx={{ width: "100%", bgcolor: "background.paper" }}>
        {renderRows()}
      </MaterialList>
      {data?.data.length > 0 && (
        <Box marginTop={2}>
          <Pagination count={lastPage} page={page} onChange={onChangePage} />
        </Box>
      )}
    </div>
  );
};
