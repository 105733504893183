import { TextField, TextFieldProps } from "@mui/material";
import "./style.scss";
import { Controller } from "react-hook-form";

type Props = {
  name: string;
  control: any;
  label?: string;
  errors?: any;
} & TextFieldProps;

export const Input: React.FC<Props> = ({ name, control, errors, ...rest }) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <TextField
              variant="outlined"
              type="text"
              size="small"
              {...field}
              error={!!errors?.[name]}
              helperText={errors?.[name]?.message}
              {...rest}
            />
          );
        }}
      />
    </>
  );
};
