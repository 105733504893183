import React from "react";
import ScrollToTop from "react-scroll-to-top";
import { Outlet } from "react-router-dom";

import HeaderBar from "../HeaderBar";

export const AuthLayout: React.FC = () => {
  return (
    <div className="auth-layout-container container-fluid">
      <HeaderBar />
      {/*<ScrollToTop  />*/}
      <div style={{ marginTop: 111 }}>
        <Outlet />
      </div>
    </div>
  );
};
