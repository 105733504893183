import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import logo from "../../assets/images/logo.png";
import { usePost } from "services";
import AppContext from "../../store/AppContext";
import { useLocalStorage } from "utils/useLocalStorage";

import "./style.scss";

export const Login = () => {
  const { showNotification } = useContext(AppContext);
  const { loading, execute } = usePost("auth/login");
  const [token, setToken] = useLocalStorage<string | null>("token", null);

  const [{ username, password }, setState] = useState({
    username: "",
    password: "",
  });

  const navigate = useNavigate();

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (username && password) {
      try {
        const { data } = await execute({
          username,
          password,
        });

        setToken(data.data.token);

        navigate("/panel");
      } catch (err) {
        showNotification("Geçersiz giriş bilgileri!", "error");
      }
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={logo} width={128} />
        <h3>Avşaroğulları</h3>
        <Box component="form" sx={{ mt: 1 }} onSubmit={onSubmit}>
          <TextField
            margin="normal"
            required
            fullWidth
            label="Kullanıcı Adı"
            autoFocus
            onChange={(e) => setState({ username: e.target.value, password })}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="Parola"
            type="password"
            onChange={(e) => setState({ username, password: e.target.value })}
          />

          <LoadingButton
            type="submit"
            loading={loading}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Giriş
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};
