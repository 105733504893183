import React from "react";
import { Routes, Route, HashRouter } from "react-router-dom";

import "./style.scss";

import { AppProvider } from "store/AppContext";

import { AuthLayout } from "../Auth";

// Pages
import { Login } from "pages/Login";
import { Currents } from "pages/Currents";

import { CurrentUpsert } from "../../pages/Currents/CurrentUpsert";
import { CurrentDetail } from "../../pages/Currents/CurrentDetail";
import { Deposits } from "../../pages/Deposits";
import { DepositDetail } from "../../pages/Deposits/DepositDetail";
import { Settings } from "../../pages/Settings";
import { DepositItems } from "../../pages/Settings/DepositItems";
import { DepositItemUpsert } from "../../pages/Settings/DepositItems/DepositItemUpsert";
import { CurrentTypes } from "../../pages/Settings/CurrentTypes";
import { CurrentTypeUpsert } from "../../pages/Settings/CurrentTypes/CurrentTypeUpsert";
import { DepositUpsert } from "../../pages/Deposits/DepositUpsert";

export const App = () => {
  return (
    <AppProvider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/panel/" element={<AuthLayout />}>
            <Route path="" element={<Currents />} />

            <Route path="currents" element={<Currents />} />
            <Route path="currents/detail/:id?" element={<CurrentDetail />} />
            <Route path="currents/add" element={<CurrentUpsert />} />
            <Route path="currents/edit/:id?" element={<CurrentUpsert />} />

            <Route path="deposits" element={<Deposits />} />
            <Route path="deposits/detail/:id?" element={<DepositDetail />} />
            <Route path="deposits/add" element={<DepositUpsert />} />
            <Route path="deposits/edit/:id?" element={<DepositUpsert />} />

            <Route path="settings" element={<Settings />} />
            <Route path="settings/deposit-items" element={<DepositItems />} />
            <Route
              path="settings/deposit-items/add"
              element={<DepositItemUpsert />}
            />
            <Route
              path="settings/deposit-items/edit/:id?"
              element={<DepositItemUpsert />}
            />

            <Route path="settings/current-types" element={<CurrentTypes />} />
            <Route
              path="settings/current-types/add"
              element={<CurrentTypeUpsert />}
            />
            <Route
              path="settings/current-types/edit/:id?"
              element={<CurrentTypeUpsert />}
            />

            <Route path="settings/current-types" element={<DepositItems />} />
          </Route>
        </Routes>
      </HashRouter>
    </AppProvider>
  );
};
