import { useCallback } from "react";
import {
  Divider,
  Fab,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { List } from "components/List";
import { useNavigate } from "react-router-dom";
import { ArrowRight } from "@mui/icons-material";

import "./style.scss";
import Typography from "@mui/material/Typography";

export enum DepositType {
  Taking = "0",
  Giving = "1",
  Done = "2",
}

export const Deposits = () => {
  const navigate = useNavigate();

  const goDetail = useCallback((item: any) => {
    navigate("/panel/deposits/detail/" + item.id);
  }, []);

  const goAdd = useCallback((item: any) => {
    navigate("/panel/deposits/add");
  }, []);

  const renderRowTemplate = useCallback((item: any) => {
    return (
      <div key={item.id}>
        <ListItem
          disablePadding
          alignItems="center"
          onClick={() => goDetail?.(item)}
        >
          <ListItemButton alignItems="center">
            <ListItemText
              primary={
                <>
                  {item.current_name} - ({" "}
                  <Typography
                    component="span"
                    color={
                      item.type === DepositType.Taking
                        ? "green"
                        : item.type === DepositType.Giving
                        ? "red"
                        : "blue"
                    }
                  >
                    {item.deposit_item_name.trim()}&nbsp;
                    {item.type === DepositType.Taking
                      ? "Alındı"
                      : item.type === DepositType.Done
                      ? "Tamamlandı"
                      : "Verildi"}
                  </Typography>
                  &nbsp;)
                </>
              }
              secondary={<>{item.description}</>}
            />
            <ArrowRight fontSize="large" />
          </ListItemButton>
        </ListItem>
        <Divider />
      </div>
    );
  }, []);

  return (
    <div>
      <List
        title="Emanetler"
        table="deposits"
        fields={[
          "deposits.id as id",
          "deposits.type as type",
          "description",
          "currents.name as current_name",
          "deposit_items.name as deposit_item_name",
        ]}
        searchFields={["description", "currents.name", "deposit_items.name"]}
        innerJoins={[
          ["currents", "deposits.current", "currents.id"],
          ["deposit_items", "deposits.deposit_item", "deposit_items.id"],
        ]}
        rowTemplate={renderRowTemplate}
        rowCountPerPage={20}
      />
      <Fab
        onClick={goAdd}
        color="primary"
        sx={{ position: "fixed", right: 20, bottom: 20 }}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};
